<template>
  <section id="dashboard-ecommerce">
    <!-- Game -->
    <b-row class="match-height">
      <b-col xl="12">
&nbsp;
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col xl="12">
&nbsp;
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col xl="1" />
      <b-col
        xl="4"
        md="5"
      >
        <leaderboard-game :data="game" />
      </b-col>
      <b-col
        xl="3"
        md="5"
      >
        <podium :data="setResults" />
      </b-col>
      <b-col
        xl="3"
        md="5"
      >
        <goals-chart :data="allTargetKPIs" />
      </b-col>
      <b-col xl="1" />
    </b-row>
    <!--/ Game -->

    <b-row
      v-if="hasGame"
      class="mb-2"
    >
      <b-col lg="1" />
      <b-col lg="10">
        <marquee>
          <span
            v-for="(performedTask) in allPerformedTasks"
            :key="performedTask.performedtaskID"
            :style="{
              marginRight: '30px',
              backgroundColor: performedTask.colorCode,
              color: 'white'
            }"
          >
            {{ getTaskString(performedTask) }}
          </span>
        </marquee>
      </b-col>
      <b-col lg="1" />
    </b-row>
    <!-- Sets & Task -->

    <b-row
      v-if="hasGame"
      class="match-height"
    >
      <b-col lg="1" />

      <b-col lg="10">
        <team-performance
          :overall-data="setResults"
          :current-data="tasks"
        />
      </b-col>
      <b-col lg="1" />
    </b-row>

    <!-- Sets Graph -->

    <b-row
      v-if="hasGame"
      class="match-height"
    >
      <b-col lg="1" />
      <b-col lg="10">
        <set-bar-chart />
      </b-col>
      <b-col lg="1" />
    </b-row>
    <!--/ Sets & Task -->

    <b-row
      v-if="hasGame"
      class="match-height"
    >
      <!-- Footer Card -->
      <b-col lg="1" />

      <b-col lg="10">
        <app-footer /></b-col>
      <b-col lg="1" />
      <!--/ Footer Card -->
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import { mapActions } from 'vuex'
import AppFooter from '@core/layouts/components/AppFooter.vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { mixinDate } from '@/constants/mixinDate'
import constants from '@/constants/static.json'
import Leaderboard from './Leaderboard'
import LeaderboardGame from './LeaderboardGame.vue'
import Podium from './Podium.vue'
import GoalsChart from './GoalsChart.vue'
import TeamPerformance from './TeamPerformance.vue'
import SetBarChart from './SetBarChart.vue'

export default {
  components: {
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    Leaderboard,
    TeamPerformance,
    SetBarChart,
    Podium,
    LeaderboardGame,
    GoalsChart,
    AppFooter,
  },
  mixins: [mixinDate],
  // eslint-disable-next-line vue/require-prop-types
  data: () => ({
    game: {
      c_string_swe: constants.COMPANY_NAME,
      startdate: '',
      enddate: '',
      visionStatement: '',
      status: '0',
      setIndex: '0',
      encrypt_id: '',
      company_logo_url: '',
    },
    hasGame: false,
    currentDate: new Date(),
    hasCompanyLogo: false,
    companyLogo: '',
    timer: '',
    tasks: [],
    setResults: [],
    allPerformedTasks: [],
    allTargetKPIs: [],
  }),
  setup() {
    const { sliceSortByValue } = Leaderboard()
    return { sliceSortByValue }
  },
  computed: {
    GetAppVersion() {
      return constants.APP_VERSION
    },
  },
  mounted() {
    this.$i18n.locale = constants.LOCALE.SWEDISH
  },
  created() {
    if (router.currentRoute.params.id) {
      this.game.encrypt_id = router.currentRoute.params.id
      this.fetchGameByID()
      this.fetchTasksByID()
      this.fetchTargetByID()
    }
    // 20 Refeshment SECONDS
    this.timer = setInterval(this.fetchGameByID, constants.RELOAD_TIME)
    this.timer = setInterval(this.fetchTasksByID, constants.RELOAD_TIME)
  },
  methods: {
    ...mapActions('onboarding', ['fetchGame',
      'fetchPerformedTasksByID',
      'fetchTargetKPIByID',
      'fetchGameSetByID',
      'fetchPerformedGroup',
      'fetchSetGameResultByID']),
    fetchGameByID() {
      this.fetchGame(this.game.encrypt_id).then(response => {
        if (response) {
          this.hasGame = true
          this.game = response
          this.$i18n.locale = this.game.language
          // eslint-disable-next-line eqeqeq
          if (this.game.company_logo_url != 'zeromedia') {
            this.companyLogo = this.game.company_logo_url
            this.hasCompanyLogo = true
          }
          this.fetchSetByID(this.game)
        }
      })
    },
    getColorByIndex(index) {
      const colors = constants.CHART_COLORS.slice(1, 3)
      return colors[index % colors.length]
    },
    fetchTargetByID() {
      this.fetchTargetKPIByID(this.game.encrypt_id).then(response => {
        if (response.data) {
          this.allTargetKPIs = response.data.filter(item => item.percentage > 0)
        }
      })
    },
    fetchTasksByID() {
      this.fetchPerformedTasksByID(this.game.encrypt_id).then(response => {
        if (response) {
          this.allPerformedTasks = response
        }
      })
    },
    async fetchSetByID(data) {
      const results = await this.fetchGameSetByID(this.game.encrypt_id)
      if (results) {
        const currentSet = this.getCurrentSet(data, results)
        if (results.length > 0 && data.status > 1) {
          const performedTasks = await this.fetchPerformedGroup({
            id: this.game.encrypt_id,
            from: this.formatDateTimeDash(currentSet.setFrom),
            to: this.formatDateTimeDash(currentSet.setTo),
          })

          const xpResult = await this.fetchSetGameResultByID({
            id: this.game.encrypt_id,
            setId: currentSet.setId,
          })

          if (xpResult.data) {
            if (xpResult.data) {
              this.setResults = this.sliceSortByValue(
                xpResult.data,
              )
            }
            if (performedTasks) {
              this.trendingUpDown(this.sliceSortByValue(performedTasks), this.setResults)
            }
          } else {
            this.tasks = performedTasks
          }
        } else {
          this.tasks = []
          const xpResult = await this.fetchSetGameResultByID({
            id: this.game.encrypt_id,
            setId: currentSet.setId,
          })
          if (xpResult.data) {
            this.setResults = this.sliceSortByValue(
              xpResult.data,
            )
          }
        }
      }
    },
    getTaskString(data) {
      return `${data.firstname}.${data.lastname.charAt(0)} - ${this.diffInDaysString(data.datetime)} - ${data.habit} [${data.points}p]`
    },
    trendingUpDown(performedTasks, setResults) {
      performedTasks.forEach(element => {
        const result = setResults.find(x => x.groupID === element.groupID)
        if (typeof result !== 'undefined') {
          if (result.place === element.place) {
            // eslint-disable-next-line no-param-reassign
            element.loss = null
            // eslint-disable-next-line no-param-reassign
            element.trending = false
          } else if (result.place > element.place) {
            // eslint-disable-next-line no-param-reassign
            element.loss = false
            // eslint-disable-next-line no-param-reassign
            element.trending = true
          } else {
            // eslint-disable-next-line no-param-reassign
            element.loss = true
            // eslint-disable-next-line no-param-reassign
            element.trending = true
          }
        }
      })

      this.tasks = performedTasks
    },
    diffInDaysString(date) {
      const today = moment(moment(Date()).format(this.DASH_DATE_TIME_FORMAT))
      const taskAt = moment(moment(date).format(this.DASH_DATE_TIME_FORMAT))
      const diffInDays = today.diff(taskAt, 'days')
      if (diffInDays < 1) {
        const diffInHours = today.diff(taskAt, 'hours')
        if (diffInHours < 2) {
          const diffInMinutes = today.diff(taskAt, 'minutes')
          return `${diffInMinutes} minutes ago`
        }
        return `${diffInHours} hours ago`
      }
      return `${diffInDays} days ago`
    },
    getCurrentSet(gameData, setData) {
      const currentDate = this.formatSqlDate(this.currentDate)
      const results = []
      let isfounded = false
      let resultSet = {}
      let setId = 0
      if (gameData.startDate > currentDate) {
        // eslint-disable-next-line no-param-reassign
        gameData.status = '1' // pending
        // eslint-disable-next-line no-param-reassign
        gameData.setIndex = 0
      } else if (gameData.endDate > currentDate) {
        // eslint-disable-next-line no-param-reassign
        gameData.status = '1' // finish
        setId = setData[setData.length - 1].settime_id
        // eslint-disable-next-line no-param-reassign
        gameData.setIndex = setData.length
      } else {
        setData.forEach((item, index) => {
        // eslint-disable-next-line eqeqeq
          if (item.settime > currentDate && !isfounded && gameData.status != 1) {
            if (index === 0) {
              isfounded = true
              // eslint-disable-next-line no-param-reassign
              gameData.status = '2' // started
              // eslint-disable-next-line no-param-reassign
              gameData.setIndex = 0
            } else {
              results.push(setData[index - 1])
              isfounded = true
              // eslint-disable-next-line no-param-reassign
              gameData.status = '3' // ongoing
              // eslint-disable-next-line no-param-reassign
              gameData.setIndex = 1
              // eslint-disable-next-line no-param-reassign
              gameData.setIndex = index
            }
          }
        })
      }

      // eslint-disable-next-line eqeqeq
      if (results.length == 0) {
        resultSet = {
          setId,
          setFrom: gameData.startdate,
          setTo: gameData.enddate,
          setIndex: gameData.setIndex,
        }
      } else {
        resultSet = {
          setId: results[0].settime_id,
          setFrom: results[0].settime,
          setTo: this.currentDate,
          setIndex: gameData.setIndex,
        }
      }
      return resultSet
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

@media only screen and (min-width: 1500px) {
  td {
    font-size: 22px;
  }
}
.column {
  padding: 0px !important;
}

marquee span {
    display: inline-block;
    background-color: white;
    margin-right: 100px;
    padding: 8px;
    border-radius: 5px;
  }

marquee {
    font-family: sans-serif;
    font-size: 12px;
    width: 100%;
    padding: 8px 0;
    background-color:#fff;
    color : white;
}
@media only screen and (min-width: 2100px) {
  .game {
    margin-left: -200px;
  }
  .column {
    margin-left: -250px !important;
  }
}
</style>
