<template>
  <b-card
    v-if="!isAllZeros"
    no-body
    class="card-revenue-budget"
  >
    <b-row>
      <b-col
        md="12"
        lg="12"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            {{ $t("TOTAL_POINTS_PER_SET") }}
          </h4>
        </div>
        <!-- chart -->
        <vue-apex-charts
          id="set-report-chart"
          type="bar"
          height="230"
          :options="chartOptions"
          :series="dataSeries.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import router from '@/router'
import constants from '@/constants/static.json'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      timer: '',
      dataSeries: {
        series: [
          {
            name: 'Task',
            data: [],
          },
        ],
      },
      chartOptions: {
        chart: {
          stacked: true,
          type: 'bar',
          toolbar: { show: false },
        },
        grid: {
          padding: {
            top: -20,
            bottom: -10,
          },
          yaxis: {
            lines: { show: false },
          },
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: constants.CHART_COLORS[1],
              fontSize: '0.86rem',
              fontFamily: 'Montserrat',
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: [constants.BACKGROUND_COLOR, constants.BACKGROUND_COLOR],
        plotOptions: {
          bar: {
            columnWidth: '40%',
          },
          distributed: true,
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '0.86rem',
              fontFamily: 'Montserrat',
            },
          },
        },
      },
    }
  },
  computed: {
    isAllZeros() {
      return this.dataSeries.series[0].data.every(element => element === 0)
    },
  },
  created() {
    this.generateReport()
    this.timer = setInterval(this.generateReport, constants.RELOAD_TIME)
  },
  methods: {
    ...mapActions('onboarding', ['fetchSetGameResults']),
    updateAxis(data) {
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          categories: data,
        },
      }
    },
    generateReport() {
      if (router.currentRoute.params.id) {
        const { id } = router.currentRoute.params
        const categoriesData = []
        this.dataSeries.series[0].data = []
        this.fetchSetGameResults(id).then(response => {
          if (response) {
            response.forEach((item, index) => {
              categoriesData.push(`Set - ${index + 1}`)
              this.dataSeries.series[0].data.push(item.points)
            })
            this.updateAxis(categoriesData)
          }
        })
      }
    },
  },
}
</script>
