<template>
  <b-card v-if="isTargetsKpiNonEmpty">
    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1">
          {{ targetsKpi.description }}
        </b-card-title>
        <b-card-text class="text-muted font-small-2">
          <span class="font-weight-bolder">{{ targetsKpi.name }}</span>
        </b-card-text>
      </b-col>
      <b-col cols="6">
        <!-- chart -->
        <vue-apex-charts
          v-if="chartOptions"
          height="170"
          :options="chartOptions"
          :series="chartSeries"
        />
      </b-col>
    </b-row>
  </b-card>
  <div v-else>
    <leaderboard-alert :data="alert" />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import constants from '@/constants/static.json'
import LeaderboardAlert from './LeaderboardAlert.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BCardTitle,
    VueApexCharts,
    LeaderboardAlert,
  },
  props: {
    data: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      targetsKpi: {
        description: '',
        percentage: 0,
        targetvalue: 0,
        floatvalue: 0,
        backgroundColor: constants.CHART_COLORS[0],
      },
      rollingIndex: 0,
      alert: 'Please configure your target to display graphs here',
      timer: '',
      chartSeries: [],
      chartOptions: null,
    }
  },
  computed: {
    isTargetsKpiNonEmpty() {
      return this.targetsKpi && Object.keys(this.targetsKpi).length > 0
    },
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        if (this.data) {
          this.targetsKpi = { ...this.data[this.rollingIndex] }
          this.initializeChartOptions(
            this.targetsKpi.targetvalue,
            this.targetsKpi.floatvalue,
            this.targetsKpi.backgroundColor,
          )
          this.initializeChartSeries()
        }
      },
    },
  },
  created() {
    this.timer = setInterval(this.rollingTargetsKpi, constants.RELOAD_TIME)
  },
  methods: {
    rollingTargetsKpi() {
      if (this.data.length === this.rollingIndex + 1) {
        this.rollingIndex = 0
      } else {
        // eslint-disable-next-line no-plusplus
        this.rollingIndex += 1
      }
      this.targetsKpi = { ...this.data[this.rollingIndex] }
      this.initializeChartOptions(
        this.targetsKpi.targetvalue,
        this.targetsKpi.floatvalue,
        this.targetsKpi.backgroundColor,
      )
      this.initializeChartSeries()
    },
    initializeChartSeries() {
      if (this.targetsKpi.percentage > 100) {
        this.chartSeries = [100, 0]
      } else {
        this.chartSeries = [
          this.targetsKpi.percentage,
          100 - this.targetsKpi.percentage,
        ]
      }
    },
    initializeChartOptions(targetvalue, floatvalue, backgroundColor) {
      this.chartOptions = {
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        labels: ['', ''],
        colors: [backgroundColor, '#FFFFFF'],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  offsetY: -15,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}`
                  },
                  fontSize: '10px',
                },
                total: {
                  show: true,
                  label: '',
                  formatter() {
                    return `${targetvalue} / ${floatvalue}`
                  },
                  fontSize: '10px',
                },
              },
            },
          },
        },
      }
    },
  },
}
</script>
