<template>
  <b-card
    v-if="data"
    no-body
  >
    <b-card-body>
      <!-- metting header -->
      <div>

        <div>
          <b-card-title class="mb-1">
            {{ strSubstring(data.c_string_swe,50) }}
          </b-card-title>
          <b-card-text>
            <p v-html="strSubstring(data.visionstatement,400)" />
          </b-card-text>
        </div>
      </div>
      <!--/ metting header -->

      <!-- media -->
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="18"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ formatDateDash(data.startdate) }} to {{ formatDateDash(data.enddate) }}
          </h6>
        </b-media-body>
      </b-media>
      <!-- avatar group -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardText, BCardTitle, BMedia,
  BMediaAside, BAvatar, VBTooltip, BMediaBody,
} from 'bootstrap-vue'
import { mixinDate } from '@/constants/mixinDate'
import { mixinList } from '@/constants/mixinValidations'

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [mixinDate, mixinList],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
