<template>
  <b-card
    v-if="data"
    no-body
    class="card-developer-meetup"
  >
    <b-card-body>
      <div class="meetup-header d-flex align-items-center">
        <div class="meetup-day">
          <feather-icon
            icon="AlertCircleIcon"
            size="34"
            class="mr-50 text-primary"
          />
        </div>
        <div class="my-auto">
          <b-card-text class="mb-2 mt-2">
            {{ data }}
          </b-card-text>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
  },
  props: {
    data: {
      type: String,
      default: () => '',
    },
  },
}
</script>
